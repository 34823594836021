
<template>
  <v-app>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
  <slot></slot>
<v-row justify="center">
  <v-col>
    <v-text-field
      v-model="nazwisko"
      :rules="emptyRules"
      label="Imię i nazwisko"
      outlined
      required
    ></v-text-field>
  </v-col>
  <v-col>
    <v-text-field
      v-model="data_urodzenia"
      :rules="emptyRules"
      label="Data i miejsce urodzenia"
      outlined
      required
    ></v-text-field>
    </v-col>
  </v-row>
    <v-text-field
      v-model="uczelnia"
      :rules="emptyRules"
      label="Nazwa uczelni i rok uzyskania dyplomu"
      outlined
      required
    ></v-text-field>
    <v-text-field
      v-model="zawod"
      :rules="emptyRules"
      label="Wykonywany zawód"
      class="mt-2"
      outlined
      required
    ></v-text-field>
    <v-text-field
      v-model="specjalizacja"
      :rules="emptyRules"
      label="Specjalizacja"
      class="mt-2"
      outlined
      required
    ></v-text-field>
    <v-textarea
          v-model="doswiadczenie"
          :rules="emptyRules"
          label="Krótki opis doświadczenia psychoterapeutycznego"
          class="mt-2"
          auto-grow
          outlined
          required
          rows="3"
          row-height="25"
        ></v-textarea>
    <v-textarea
          v-model="plany"
          :rules="emptyRules"
          label="W jaki sposób Pani/Pan planuje pracować terapeutycznie i klinicznie w trakcie trwania kursu (4 lata)"
          class="mt-2"
          auto-grow
          outlined
          required
          rows="3"
          row-height="25"
        ></v-textarea>
            <v-textarea
                  v-model="motywacje"
                  :rules="emptyRules"
                  label="Jakie są Pani/Pana motywacje zgłoszenia się na szkolenie"
                  class="mt-2"
                  auto-grow
                  outlined
                  required
                  rows="3"
                  row-height="25"
                ></v-textarea>
                <v-textarea
                      v-model="oczekiwania"
                      :rules="emptyRules"
                      label="Jakie są Pani/Pana oczekania wobec szkolenia"
                      class="mt-2"
                      auto-grow
                      outlined
                      required
                      rows="3"
                      row-height="25"
                    ></v-textarea>


<p class="h4 text-center" >Dane kontaktowe</p>
<v-textarea
      v-model="kontakt"
      :rules="emptyRules"
      label="Adres korespondencyjny"
      auto-grow
      outlined
      required
      rows="3"
      row-height="25"
    ></v-textarea>
    <v-textarea
          v-model="faktura"
          label="Dane do faktury (imię, nazwisko lub nazwa firmy,adres,NIP)"
          auto-grow
          outlined
          rows="3"
          row-height="25"
        ></v-textarea>
    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="adres e-mail"
      outlined
      required
    ></v-text-field>
    <v-text-field
      v-model="telefon"
      :rules="emptyRules"
      label="numer telefonu"
      outlined
      required
    ></v-text-field>
    <v-textarea
          v-model="uwagi"
          label="uwagi"
          auto-grow
          outlined
          rows="3"
          row-height="25"
        ></v-textarea>

    <v-checkbox
      v-model="newsletter"
      label="chcę otrzymywać informacje o naszych szkoleniach drogą mailową"
      class="label_position"
    ></v-checkbox>
    <v-checkbox
      v-model="zgoda"
      :rules="[v => !!v || 'Jeśli nie wyrazisz zgody nie możemy zapisać i przetwarzać Twoich danych']"
      label="wyrażam zgodę"
      class="my-n4 label_position"
      required
    ></v-checkbox>
    <p class="ml-6">na przetwarzanie moich danych osobowych dla celów związanych z obsługą i organizacją zamawianego szkolenia.
      <br>Informujemy, że administratorem danych osobowych będzie: Crescentia, z siedzibą w Warszawie, ul. Mickiewicza 8/6.
      Podanie danych osobowych ma charakter dobrowolny oraz przysługuje prawo dostępu do nich, ich poprawiania i
      anulowania.</p>
<div class="text-center pt-5">
    <v-btn
      :disabled="!valid"
      color="#00C853"
      class="tlbtn mr-4"
      elevation="0"
      large
      dark
      
      @click.prevent="store"
    >
      wyślij zgłoszenie
    </v-btn>
</div>
  </v-form>
</v-app>
</template>

<script>
  export default {
    props: ['course_id'],
    data: () => ({
      valid: true,
      nazwisko: '',
      data_urodzenia: '',
      uczelnia: '',
      zawod: '',
      specjalizacja: '',
      doswiadczenie: '',
      plany: '',
      motywacje: '',
      oczekiwania: '',
      kontakt: '',
      faktura: '',
      telefon: '',
      uwagi: '',
      newsletter: '',
      zgoda: '',

      emptyRules: [
        v => !!v || 'wypełnienie pola wymagane',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail jest wymagany',
        v => /.+@.+\..+/.test(v) || 'E-mail musi być poprawny',
      ],

    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      store () {
        axios.post('/orderstore',{
                course_id: this.course_id,
                nazwisko: this.nazwisko,
                data_urodzenia: this.data_urodzenia,
                uczelnia: this.uczelnia,
                zawod: this.zawod,
                specjalizacja: this.specjalizacja,
                doswiadczenie: this.doswiadczenie,
                plany: this.plany,
                motywacje: this.motywacje,
                oczekiwania: this.oczekiwania,
                kontakt: this.kontakt,
                faktura: this.faktura,
                telefon: this.telefon,
                uwagi: this.uwagi,
                newsletter: this.newsletter,
                zgoda: this.zgoda,
                email: this.email,
                })
                .then(function (response) {
                  window.location = response.data.redirect;
                })
                .catch(function (error) {
                  console.log(error.response);
                });
              },
    },
  }
</script>
<style lang="sass">
  .label_position label
    margin-bottom: 0.2rem
  .v-application--wrap
    min-height: 1vh
  .v-application
    font-family: "Mulish"
</style>
