<template>
  <v-app>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  ><slot></slot>
  <v-row justify="center">
    <v-col class="mt-3">
      <v-text-field
        v-model="name"
        :rules="emptyRules"
        label="Nazwa"
        :counter="30"
        outlined
        required
      ></v-text-field>

    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="Adres e-mail"
      :counter="200"
      outlined
      required
    ></v-text-field>
    <div class="text-center pt-5">
        <v-btn
          :disabled="!valid"
          elevation="0"
          color="success"
          class="mr-4"
          @click.prevent="store"
        >
        zapisz się !
        </v-btn>
      </div>
  </v-col>
</v-row>
</v-form>
</v-app>
</template>

<script>
  export default {
    data: () => ({
      valid: false,
      name: '',
      emptyRules: [
        v => v.length <= 30 || 'maksymalnie 30 znaków',

      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail jest wymagany',
        v => /.+@.+\..+/.test(v) || 'E-mail musi być poprawny',
        v => v.length <= 200 || 'maksymalnie 200 znaków',
      ],

    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      store: function(){
        axios.post('/newsletterstore',{
                name: this.name,
                email: this.email,
                })
                .then(function (response) {
                  window.location = response.data.redirect;
                })
                .catch(function (error) {
                  console.log(error.response);
                });
              },
    },
  }
</script>
