
<template>
  <v-app id="app">
    <v-select
          v-model="cours"

          :items="courses"
          item-text="name"
          item-value="id"
          label="Select"

          return-object
          outlined
        ></v-select>

</v-app>
</template>

<script>
  export default {
    props: ['singlecourse_id', 'courses'],
    data: () => ({
      cours: {},
      valid: true,
      nazwisko: '',
      data_urodzenia: '',
      uczelnia: '',
      zawod: '',
      specjalizacja: '',
      doswiadczenie: '',
      plany: '',
      motywacje: '',
      oczekiwania: '',
      kontakt: '',
      faktura: '',
      telefon: '',
      uwagi: '',
      newsletter: '',
      zgoda: '',

      emptyRules: [
        v => !!v || 'wypełnienie pola wymagane',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail jest wymagany',
        v => /.+@.+\..+/.test(v) || 'E-mail musi być poprawny',
      ],

    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      store () {
        axios.post('/orderstore',{
                singlecourse_id: this.singlecourse_id,
                nazwisko: this.nazwisko,
                data_urodzenia: this.data_urodzenia,
                uczelnia: this.uczelnia,
                zawod: this.zawod,
                specjalizacja: this.specjalizacja,
                doswiadczenie: this.doswiadczenie,
                plany: this.plany,
                motywacje: this.motywacje,
                oczekiwania: this.oczekiwania,
                kontakt: this.kontakt,
                faktura: this.faktura,
                telefon: this.telefon,
                uwagi: this.uwagi,
                newsletter: this.newsletter,
                zgoda: this.zgoda,
                email: this.email,
                })
                .then(function (response) {
                  window.location = response.data.redirect;
                })
                .catch(function (error) {
                  console.log(error.response);
                });
              },
    },
  }
</script>
<style lang="sass">
  .label_position label
    margin-bottom: 0.2rem
  .v-application--wrap
    min-height: 1vh
  .v-application
    font-family: "Mulish"
</style>
