<template>
  <section id="timeline" >
  <v-app>
  <v-timeline
    align-top
    :dense="$vuetify.breakpoint.smAndDown"

  >
    <v-timeline-item
      v-for="(item, i) in courses_array"
      :key="i"
      :color="item.color"
      :icon="item.icon"
      fill-dot
      elevation="6"
    >
    <span slot="opposite"
          class="headline font-weight-bold"
          :style="'color: ' + item.color + ';'"
          v-text="item.name"

        ></span>
    <span ></span>
    <v-hover v-if="item.picture">
    <template v-slot:default="{ hover }">
     <v-card
     :color="item.color"
     
     dark
     >
     <v-card-title class="card_title">
       {{item.city.name}} &ndash; {{item.edition}}
     </v-card-title>
     <v-card-text class="white text-center text--primary pa-0">

     <v-img
       v-if="item.picture"
       :src="'../storage/img/edition/' + item.picture"
       max-height="250"
       class="ma-0"
     ></v-img>
       <v-fade-transition>
         <v-overlay
           v-if="hover"
           absolute
           color="#036358"
         >
         <v-btn
               color="#00C853"
               elevation="0"
               class="tlbtn"
               large
               dark
               @click.stop="picture(item.picture)"
             >
               zdjęcie
             </v-btn>
         </v-overlay>
       </v-fade-transition>
         </v-card-text>
     </v-card>
    </template>
    </v-hover>
      <v-card
        v-else
        :color="item.color"
        dark
      >
        <v-card-title class="card_title">
          {{item.city.name}} &ndash; {{item.edition}}
        </v-card-title>
        <v-card-text class="white text-center text--primary pa-0">
          <v-img
            v-if="item.picture"
            :src="'../storage/img/edition/' + item.picture"
            max-height="250"
            class="ma-0"
          ></v-img>
          <div v-else class="pb-3">
            <div class="pt-3 txttitle">{{item.title}}</div>
            <div class="pt-1 red--text">{{item.subtitle}}</div>
          </div>
          <v-btn
            v-if="!item.full"
              color="#00C853"
              elevation="0"
              class="mb-4 tlbtn"
              large
              v-on:click="go_szkolenia"
            >
              rekrutacja
            </v-btn>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
  <v-dialog
        v-model="dialog"
        max-width="700"
      >
        <v-card>
            <v-img
              :src="'../storage/img/edition/' + this.image"
              class="ma-0"
            ></v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              zamknij
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-app>
</section>
</template>
<script>
  export default {
    props: ['courses_array'],
    data: () => ({
      dialog: false,
      image: '1.jpg',
    }),
    methods: {
      go_szkolenia() {
        window.location.replace('/szkolenia');
      },
      picture(picture) {
         this.image = picture,
         this.dialog = true
      },
    },
  }
</script>
  <style lang="sass">
    .tlbtn
      font-family: Mulish
      letter-spacing: 0.05em
      font-size: 14px
      font-weight: bold
    .txttitle
      color: #005073
    .card_title
      font-size: 18px

  </style>
